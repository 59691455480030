import {
  CLEAR_FBP_PARAMETERS,
  SET_FBP_NOTIFICATION_PARAMETER,
  CLEAR_FBP_NOTIFICATION_PARAMETER,
} from "../actionTypes/foundationalBestPractices";

const initialState = {
  parameters: {},
  notificationParameter: [],
};

const foundationBestPracticesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_FBP_PARAMETERS: {
      return {
        ...state,
        parameters: initialState.parameters,
      };
    }
    case SET_FBP_NOTIFICATION_PARAMETER:
      return {
        ...state,
        notificationParameter: action.payload,
      };
    case CLEAR_FBP_NOTIFICATION_PARAMETER:
      return {
        ...state,
        notificationParameter: initialState.notificationParameter,
      };
    default: {
      return { ...state };
    }
  }
};

export default foundationBestPracticesReducer;
