import React, { useState } from "react";
import {
  isSingleLineEllipsisApplied,
  isMultiLineEllipsisApplied,
} from "../helpers/ellipsesCheck";
import { Tooltip } from "reactstrap";
import classNames from "classnames";
import { useId } from "react";

const EllipsisWithTooltip = ({
  text,
  className,
  isMultiLineEllipsis = false,
}) => {
  const tooltipId = useId();

  const [showTitleTooltip, setShowTitleTooltip] = useState(false);

  const handleMouseOver = (e) => {
    isMultiLineEllipsis
      ? setShowTitleTooltip(isMultiLineEllipsisApplied(e.target))
      : setShowTitleTooltip(isSingleLineEllipsisApplied(e.target));
  };

  const handleMouseLeave = (e) => {
    setShowTitleTooltip(false);
  };

  return (
    <span
      className={classNames(className, "mc-text-base-regular")}
      id={`id-${tooltipId.replace(/:/g, "")}`}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      {text}
      <Tooltip
        placement="bottom"
        isOpen={showTitleTooltip}
        target={`id-${tooltipId.replace(/:/g, "")}`}
        autohide={false}
      >
        {text}
      </Tooltip>
    </span>
  );
};

export default EllipsisWithTooltip;
