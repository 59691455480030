export const ADD_RESOURCES_DEPARTMENT = "ADD_RESOURCE_DEPARTMENT";
export const SET_DEPARTMENT_NAME = "SET_DEPARTMENT_NAME";
export const SAVE_DEPARTMENT = "SAVE_DEPARTMENT";
export const GET_DEPARTMENT_RESOURCES_REQUEST =
  "GET_DEPARTMENT_RESOURCES_REQUEST";
export const GET_DEPARTMENT_RESOURCES_SUCCESS =
  "GET_DEPARTMENT_RESOURCES_SUCCESS";
export const GET_DEPARTMENT_RESOURCES_FAILURE =
  "GET_DEPARTMENT_RESOURCES_FAILURE";
export const GET_DEPARTMENT_DETAILS_REQUEST = "GET_DEPARTMENT_DETAILS_REQUEST";
export const GET_DEPARTMENT_DETAILS_SUCCESS = "GET_DEPARTMENT_DETAILS_SUCCESS";
export const GET_DEPARTMENT_DETAILS_FAILURE = "GET_DEPARTMENT_DETAILS_FAILURE";

export const SET_LOAD_ALL_RESOURCES = "SET_LOAD_ALL_RESOURCES";

export const GET_ALL_DEPARTMENT_RESOURCES_REQUEST =
  "GET_ALL_DEPARTMENT_RESOURCES_REQUEST";
export const GET_ALL_DEPARTMENT_RESOURCES_FAILURE =
  "GET_ALL_DEPARTMENT_RESOURCES_FAILURE";

export const GET_PENDING_PROJECT_REQUESTS = "GET_PENDING_PROJECT_REQUESTS";
export const GET_PENDING_PROJECT_REQUESTS_SUCCESS =
  "GET_PENDING_PROJECT_REQUESTS_SUCCESS";
export const GET_PENDING_PROJECT_REQUESTS_FAILURE =
  "GET_PENDING_PROJECT_REQUESTS_FAILURE";

export const GET_INACTIVE_PROJECT_REQUESTS = "GET_INACTIVE_PROJECT_REQUESTS";
export const GET_INACTIVE_PROJECT_REQUESTS_SUCCESS =
  "GET_INACTIVE_PROJECT_REQUESTS_SUCCESS";
export const GET_INACTIVE_PROJECT_REQUESTS_FAILURE =
  "GET_PENDING_PROJECT_REQUESTS_FAILURE";

export const RESET_ADD_PROJECT_REQUESTS = "RESET_ADD_PROJECT_REQUESTS";
export const ADD_PROJECT_REQUESTS = "ADD_PROJECT_REQUESTS";
export const ADD_PROJECT_REQUESTS_SUCCESS = "ADD_PROJECT_REQUESTS_SUCCESS";
export const ADD_PROJECT_REQUESTS_FAILURE = "ADD_PROJECT_REQUESTS_FAILURE";

export const RESET_WITHDRAW_PROJECT_REQUEST = "RESET_WITHDRAW_PROJECT_REQUEST";
export const WITHDRAW_PROJECT_REQUEST = "WITHDRAW_PROJECT_REQUEST";
export const WITHDRAW_PROJECT_REQUEST_SUCCESS =
  "WITHDRAW_PROJECT_REQUEST_SUCCESS";
export const WITHDRAW_PROJECT_REQUEST_FAILURE =
  "WITHDRAW_PROJECT_REQUEST_FAILURE";

export const GET_DEPARTMENTS_LIST = "GET_DEPARTMENTS_LIST";
export const GET_DEPARTMENTS_LIST_SUCCESS = "GET_DEPARTMENTS_LIST_SUCCESS";
export const GET_DEPARTMENTS_LIST_FAILURE = "GET_DEPARTMENTS_LIST_FAILURE";

export const RESET_CREATE_DEPARTMENT = "RESET_CREATE_DEPARTMENT";
export const CREATE_DEPARTMENT = "CREATE_DEPARTMENT";
export const CREATE_DEPARTMENT_SUCCESS = "CREATE_DEPARTMENT_SUCCESS";
export const CREATE_DEPARTMENT_FAILURE = "CREATE_DEPARTMENT_FAILURE";

export const GET_DEPARTMENTS_POTENTIAL_SAVINGS =
  "GET_DEPARTMENTS_POTENTIAL_SAVINGS";
export const GET_DEPARTMENTS_POTENTIAL_SAVINGS_SUCCESS =
  "GET_DEPARTMENTS_POTENTIAL_SAVINGS_SUCCESS";
export const GET_DEPARTMENTS_POTENTIAL_SAVINGS_FAILURE =
  "GET_DEPARTMENTS_POTENTIAL_SAVINGS_FAILURE";

export const GET_DEPARTMENTS_OPEN_OPS_ISSUES =
  "GET_DEPARTMENTS_OPEN_OPS_ISSUES";
export const GET_DEPARTMENTS_OPEN_OPS_ISSUES_SUCCESS =
  "GET_DEPARTMENTS_OPEN_OPS_ISSUES_SUCCESS";
export const GET_DEPARTMENTS_OPEN_OPS_ISSUES_FAILURE =
  "GET_DEPARTMENTS_OPEN_OPS_ISSUES_FAILURE";
export const RESET_DEPARTMENTS_OPEN_OPS_ISSUES =
  "RESET_DEPARTMENTS_OPEN_OPS_ISSUES";

export const GET_DEPARTMENT_APPLICATIONS = "GET_DEPARTMENT_APPLICATIONS";
export const GET_DEPARTMENT_APPLICATIONS_SUCCESS =
  "GET_DEPARTMENT_APPLICATIONS_SUCCESS";
export const GET_DEPARTMENT_APPLICATIONS_FAILURE =
  "GET_DEPARTMENT_APPLICATIONS_FAILURE";

export const RESET_SAVE_DEPARTMENT_REQUEST = "RESET_SAVE_DEPARTMENT_REQUEST";
export const SAVE_DEPARTMENT_REQUEST = "SAVE_DEPARTMENT_REQUEST";
export const SAVE_DEPARTMENT_SUCCESS = "SAVE_DEPARTMENT_SUCCESS";
export const SAVE_DEPARTMENT_FAILURE = "SAVE_DEPARTMENT_FAILURE";

export const RESET_DENY_PROJECT_REQUEST = "RESET_DENY_PROJECT_REQUEST";
export const DENY_PROJECT_REQUEST = "DENY_PROJECT_REQUEST";
export const DENY_PROJECT_REQUEST_SUCCESS = "DENY_PROJECT_REQUEST_SUCCESS";
export const DENY_PROJECT_REQUEST_FAILURE = "DENY_PROJECT_REQUEST_FAILURE";

export const RESET_APPROVE_PROJECT_REQUEST = "RESET_APPROVE_PROJECT_REQUEST";
export const APPROVE_PROJECT_REQUEST = "APPROVE_PROJECT_REQUEST";
export const APPROVE_PROJECT_REQUEST_SUCCESS =
  "APPROVE_PROJECT_REQUEST_SUCCESS";
export const APPROVE_PROJECT_REQUEST_FAILURE =
  "APPROVE_PROJECT_REQUEST_FAILURE";

export const RESET_EDIT_PROJECT_REQUEST = "RESET_EDIT_PROJECT_REQUEST";
export const EDIT_PROJECT_REQUEST = "EDIT_PROJECT_REQUEST";
export const EDIT_PROJECT_REQUEST_SUCCESS = "EDIT_PROJECT_REQUEST_SUCCESS";
export const EDIT_PROJECT_REQUEST_FAILURE = "EDIT_PROJECT_REQUEST_FAILURE";

export const RESET_UPDATE_PROJECT_REQUEST = "RESET_UPDATE_PROJECT_REQUEST";
export const UPDATE_PROJECT_REQUEST = "UPDATE_PROJECT_REQUEST";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
export const UPDATE_PROJECT_FAILURE = "UPDATE_PROJECT_FAILURE";

export const RESET_DELETE_PROJECT_REQUEST = "RESET_DELETE_PROJECT_REQUEST";
export const DELETE_PROJECT_REQUEST = "DELETE_PROJECT_REQUEST";
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS";
export const DELETE_PROJECT_FAILURE = "DELETE_PROJECT_FAILURE";
