import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../../config/customBaseQuery";
import {
  WAR_ASSESSMENT_WORKLOAD_BASE_URL,
  WAR_ASSESSMENT_BEST_PRACTICES,
} from "../../../config/service";
import { isEqual } from "lodash";

export const wafrFindingsFiltersApi = createApi({
  reducerPath: "wafrFindingsFilters",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getResourceTypeOptions: builder.query({
      query: ({ workloadId, queryParams }) => {
        return {
          url: `${WAR_ASSESSMENT_WORKLOAD_BASE_URL}${workloadId}/resource-types?${queryParams}`,
          method: "GET",
        };
      },
      refetchOnMountOrArgChange: true,
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const { workloadId } = queryArgs;
        return `${endpointName}-${workloadId}`;
      },
      merge: (currentCache, newItems) => {
        const itemsMap = new Map();
        [currentCache?.Items, newItems?.Items].forEach((array) => {
          array?.forEach((item) => itemsMap.set(item.value, item));
        });
        currentCache.Items = Array.from(itemsMap.values());
        currentCache.Offset = newItems?.Offset;
      },
      forceRefetch({ currentArg, previousArg }) {
        return !isEqual(currentArg, previousArg);
      },
      transformResponse: (response) => {
        return {
          Items: response.Items.map((resourceType) => ({
            label: resourceType,
            value: resourceType,
          })),
          Offset: response?.Offset,
        };
      },
    }),
    getMilestoneResourceTypeOptions: builder.query({
      query: ({ workloadId, milestoneId, queryParams }) => {
        return {
          url: `${WAR_ASSESSMENT_WORKLOAD_BASE_URL}${workloadId}/milestone/${milestoneId}/resource-types?${queryParams}`,
          method: "GET",
        };
      },
      refetchOnMountOrArgChange: true,
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const { workloadId, milestoneId, queryParams } = queryArgs;
        return `${endpointName}-${workloadId}-${milestoneId}-${queryParams}`;
      },
      merge: (currentCache, newItems) => {
        currentCache?.Items.push(...newItems?.Items);
        currentCache.Offset = newItems?.Offset;
      },
      forceRefetch({ currentArg, previousArg }) {
        return !isEqual(currentArg, previousArg);
      },
      transformResponse: (response) => {
        return {
          Items: response.Items.map((resourceType) => ({
            label: resourceType,
            value: resourceType,
          })),
          Offset: response?.Offset,
        };
      },
    }),
    getQuestionOptions: builder.query({
      query: ({ queryParams }) => {
        return {
          url: `${WAR_ASSESSMENT_WORKLOAD_BASE_URL}questions?${queryParams}`,
          method: "GET",
        };
      },
      refetchOnMountOrArgChange: true,
      serializeQueryArgs: ({ endpointName }) => {
        return `${endpointName}`;
      },
      merge: (currentCache, newItems) => {
        const itemsMap = new Map();
        [currentCache?.Items, newItems?.Items].forEach((array) => {
          array?.forEach((item) => itemsMap.set(item.value, item));
        });
        currentCache.Items = Array.from(itemsMap.values());
        currentCache.Offset = newItems?.Offset;
      },
      forceRefetch({ currentArg, previousArg }) {
        return !isEqual(currentArg, previousArg);
      },
      transformResponse: (response) => {
        return {
          Items: response.Items.map((question) => ({
            label: question.Question,
            value: question.QuestionId,
          })),
          Offset: response?.Offset,
        };
      },
    }),
    getBestPracticesOptions: builder.query({
      query: ({ queryParams }) => {
        return {
          url: `${WAR_ASSESSMENT_WORKLOAD_BASE_URL}${WAR_ASSESSMENT_BEST_PRACTICES}?${queryParams}`,
          method: "GET",
        };
      },
      refetchOnMountOrArgChange: true,
      serializeQueryArgs: ({ endpointName }) => {
        return `${endpointName}`;
      },
      merge: (currentCache, newItems) => {
        const itemsMap = new Map();
        [currentCache?.Items, newItems?.Items].forEach((array) => {
          array?.forEach((item) => itemsMap.set(item.value, item));
        });
        currentCache.Items = Array.from(itemsMap.values());
        currentCache.Offset = newItems?.Offset;
      },
      forceRefetch({ currentArg, previousArg }) {
        return !isEqual(currentArg, previousArg);
      },
      transformResponse: (response) => {
        return {
          Items: response.Items.map(({ BestPractice, BestPracticeId }) => ({
            label: BestPractice,
            value: BestPracticeId,
          })),
          Offset: response?.Offset,
        };
      },
    }),
  }),
});

export const {
  useGetResourceTypeOptionsQuery,
  useGetMilestoneResourceTypeOptionsQuery,
  useGetQuestionOptionsQuery,
  useGetBestPracticesOptionsQuery,
} = wafrFindingsFiltersApi;
