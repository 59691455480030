export const SET_REPORT_SCOPE = "SET_REPORT_SCOPE";

export const REPORTS_SHOW_DATE_PICKER_FIELD = "REPORTS_SHOW_DATE_PICKER_FIELD";
export const REPORTS_SHOW_YEAR_PICKER_FIELD = "REPORTS_SHOW_YEAR_PICKER_FIELD";

export const REPORTS_SET_SELECTED_DATE = "REPORTS_SET_SELECTED_DATE";
export const REPORTS_SET_SELECTED_YEAR = "REPORTS_SET_SELECTED_YEAR";

export const REPORTS_SHOW_POLICY_PICKER_FIELD =
  "REPORTS_SHOW_POLICY_PICKER_FIELD";
export const REPORTS_SHOW_POLICY_PICKER_MODAL =
  "REPORTS_SHOW_POLICY_PICKER_MODAL";
export const REPORTS_SET_SELECTED_POLICY = "REPORTS_SET_SELECTED_POLICY";

export const REPORTS_SHOW_GROUP_PICKER_FIELD =
  "REPORTS_SHOW_GROUP_PICKER_FIELD";
export const REPORTS_SHOW_GROUP_PICKER_MODAL =
  "REPORTS_SHOW_GROUP_PICKER_MODAL";
export const REPORTS_SET_SELECTED_GROUP = "REPORTS_SET_SELECTED_GROUP";

export const REPORTS_SHOW_APP_PICKER_FIELD = "REPORTS_SHOW_APP_PICKER_FIELD";
export const REPORTS_SHOW_APP_PICKER_MODAL = "REPORTS_SHOW_APP_PICKER_MODAL";
export const REPORTS_SET_SELECTED_APP = "REPORTS_SET_SELECTED_APP";
export const REPORTS_SET_SELECTED_DEPT = "REPORTS_SET_SELECTED_DEPT";
export const REPORTS_SHOW_DEPT_PICKER_MODAL = "REPORTS_SHOW_DEPT_PICKER_MODAL";

export const RESET_REPORTS_CRITERIA = "RESET_REPORTS_CRITERIA";

export const REPORTS_SET_SELECTED_MILESTONE = "REPORTS_SET_SELECTED_MILESTONE";
export const WAFR_REPORTS_SET_FILTERS = "WAFR_REPORTS_SET_FILTERS";
