import { getAxiosInstance } from "../../helpers/apiServiceHelper";
import { DOMAIN_NAMES } from "../../utils/app-constants.json";

const controller = new AbortController();
export const axiosBaseQuery =
  () =>
  async ({
    url,
    method,
    params = {},
    body,
    headers = {},
    cancelSignal = controller.signal,
  }) => {
    try {
      const urlPaths = url.split("/");
      let urlDomain = url.startsWith("/") ? urlPaths[1] : urlPaths[0];

      const domainWithFilters = urlDomain.split("?"); // For cases like /notifications?filter=org
      // Expected values for urlDomain to be org/auth or any other values from DOMAIN_NAMES constant
      urlDomain =
        domainWithFilters.length > 1 ? domainWithFilters[0] : urlDomain;

      const isWithCredentialsEnabled = DOMAIN_NAMES.some(
        (domain) => urlDomain === domain
      );

      const result = await getAxiosInstance({
        method,
        url,
        params,
        isWithCredentialsEnabled,
        cancelSignal,
        body,
        headers: headers,
      });
      return {
        data: result.data,
        meta: {
          headers: result.headers,
        },
      };
    } catch (error) {
      const err = error;
      return {
        error: err,
      };
    }
  };
