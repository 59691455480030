import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../../config/customBaseQuery";
import {
  COST_OPTIMIZATION_SUMMARY,
  ORG_BASE_URL,
} from "../../../config/service";

export const optimizationSummaryApi = createApi({
  reducerPath: "optimizationSummary",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getOptimizationSummary: builder.query({
      query: ({ metric }) => {
        const encodedMetric = encodeURIComponent(metric);
        return {
          url: `${ORG_BASE_URL}${COST_OPTIMIZATION_SUMMARY}?Metric=${encodedMetric}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const { useGetOptimizationSummaryQuery } = optimizationSummaryApi;
