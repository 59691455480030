import {
  SAVE_COMMON_SELECTED_ACCOUNTS,
  SAVE_ACCOUNT_PERMISSIONS,
  RESET_COMMONS_DATA,
  GET_COMMON_ACCOUNTS_REQUEST,
  GET_COMMON_ACCOUNTS_SUCCESS,
  GET_COMMON_ACCOUNTS_FAILURE,
  DO_RESOURCES_EXIST_REQUEST,
  DO_RESOURCES_EXIST_SUCCESS,
  DO_RESOURCES_EXIST_FAILURE,
  SET_FETCH_BP_PARAMETERS,
  SET_RESET_RESOURCE_PARAMETERS,
  SAVE_COMMON_ADDED_NOTIFICATIONS,
  SAVE_PAGINATION_PAGE_DETAILS,
  GET_ACCOUNT_PERMISSION_CFN_URL_REQUEST,
  GET_ACCOUNT_PERMISSION_CFN_URL_SUCCESS,
  GET_ACCOUNT_PERMISSION_CFN_URL_FAILURE,
  CLEAR_UPDATE_PERMISSION_DATA,
  SAVE_MULTI_SCOPE_FILTERS,
  SAVE_MULTI_TREE_VIEW_FILTERS,
  SAVE_ADDED_ACTIONS,
  SAVE_ACTIONS_API_RESPONSE,
  SAVE_COMMON_FILTERS_DATA,
  SET_SELECTED_REGION_FILTER,
  SET_SELECTED_PROJECT_FILTER,
  SET_SELECTED_ACCOUNT_FILTER,
  SET_SELECTED_ENVIRONMENT_FILTER,
  SET_SELECTED_FINDINGS_FILTER,
  SAVE_GEN_REPORT_MULTI_FILTERS_DATA,
  SET_GEN_REPORT_SCOPE_TYPE,
  TOGGLE_ACCOUNT_IN_SELECTED_SCOPE_DATA,
  TOGGLE_ENVIRONMENTS_IN_SELECTED_SCOPE_DATA,
  TOGGLE_PROJECT_IN_SELECTED_SCOPE_DATA,
  RESET_GEN_REPORT_SCOPE_DATA,
  SAVE_GEN_REPORT_FILTER_SCOPE_DATA,
  TOGGLE_SELECT_ALL_PROJECT_IN_SCOPE_DATA,
  SAVE_GEN_REPORT_MULTI_SCOPE_DATA,
  CLEAR_ADDED_ACTIONS,
} from "../actionTypes/commons";
import { PAGE_NUMBER, PAGE_SIZE } from "../../../utils/app-constants.json";
import { SCOPE_TYPE } from "../components/report-target-scope-selector/common";

const initialState = {
  accountsFetched: false,
  accountPermissions: [],
  actionsApiResponse: null,
  addedActions: [],
  addedNotifications: [],
  allAccounts: [],
  cfnData: null,
  commonFilters: null,
  selectedRegions: null,
  selectedProjects: null,
  selectedAccountsData: null,
  selectedEnvironments: null,
  debouncedFindingSearchValue: null,
  fetchBPParameters: false,
  fetchingAccounts: false,
  fetchingAccountsFailure: false,
  fetchingAccountPermissions: false,
  fetchingAccountPermissionsFailure: false,
  fetchingCfnUrl: true,
  fetchingCfnUrlFailed: false,
  generateReportMultiScopeAppliedFilters: {},
  generateReportMultiScopeFilters: {},
  generateReportSelectedScopeType: SCOPE_TYPE.ACCOUNTS, // projects || accounts
  generateReportSelectedScopeData: {
    projects: {},
    accounts: {},
  },
  multiScopeFilters: null,
  multiTreeViewFilters: null,
  pageNumber: PAGE_NUMBER,
  pageSize: PAGE_SIZE,
  resetResourceParameters: false,
  selectedAccounts: [],
  selectedRegions: null,
};

const commons = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SELECT_ALL_PROJECT_IN_SCOPE_DATA:
      return {
        ...state,
        generateReportSelectedScopeData: {
          projects: {},
          accounts: {},
        },
      };
    case TOGGLE_ENVIRONMENTS_IN_SELECTED_SCOPE_DATA:
      return {
        ...state,
        generateReportSelectedScopeData: {
          ...state.generateReportSelectedScopeData,
          projects: action.payload,
        },
      };
    case TOGGLE_PROJECT_IN_SELECTED_SCOPE_DATA:
      return {
        ...state,
        generateReportSelectedScopeData: {
          ...state.generateReportSelectedScopeData,
          projects: action.payload,
        },
      };
    case RESET_GEN_REPORT_SCOPE_DATA:
      return {
        ...state,
        generateReportMultiScopeAppliedFilters: {},
        generateReportSelectedScopeType: SCOPE_TYPE.PROJECTS,
        generateReportSelectedScopeData: {
          projects: {},
          accounts: {},
        },
        multiScopeFilters: {},
      };
    case SAVE_GEN_REPORT_FILTER_SCOPE_DATA:
      return {
        ...state,
        generateReportMultiScopeAppliedFilters: action.payload,
        multiScopeFilters: {
          ...state.multiScopeFilters,
          filters: action.payload,
        },
      };
    case TOGGLE_ACCOUNT_IN_SELECTED_SCOPE_DATA:
      return {
        ...state,
        generateReportSelectedScopeData: {
          ...state.generateReportSelectedScopeData,
          accounts: {
            ...action.payload,
          },
        },
      };

    case SET_GEN_REPORT_SCOPE_TYPE:
      return {
        ...state,
        generateReportSelectedScopeType: action.payload,
        generateReportMultiScopeAppliedFilters: {},
        generateReportSelectedScopeData: {
          projects: {},
          accounts: {},
        },
      };

    case SAVE_GEN_REPORT_MULTI_FILTERS_DATA:
      let { payload } = action;
      return {
        ...state,
        generateReportMultiScopeFilters: { ...payload },
      };

    case SAVE_GEN_REPORT_MULTI_SCOPE_DATA:
      return {
        ...state,
        generateReportSelectedScopeData: action.payload,
      };

    case SAVE_COMMON_SELECTED_ACCOUNTS:
      return {
        ...state,
        selectedAccounts: action.payload,
      };
    case SAVE_ACCOUNT_PERMISSIONS:
      return {
        ...state,
        accountPermissions: action.payload,
      };
    case SAVE_MULTI_SCOPE_FILTERS:
      return {
        ...state,
        multiScopeFilters: action.payload,
      };
    case SAVE_MULTI_TREE_VIEW_FILTERS:
      return {
        ...state,
        multiTreeViewFilters: action.payload,
      };
    case RESET_COMMONS_DATA:
      return {
        ...state,
        ...initialState,
        allAccounts: initialState.allAccounts,
        integrationList: initialState.integrationList,
      };
    case SET_FETCH_BP_PARAMETERS:
      return {
        ...state,
        fetchBPParameters: action.payload,
      };
    case SET_RESET_RESOURCE_PARAMETERS:
      return {
        ...state,
        resetResourceParameters: action.payload,
      };
    case SAVE_COMMON_ADDED_NOTIFICATIONS:
      return {
        ...state,
        addedNotifications: action.payload,
      };
    case GET_COMMON_ACCOUNTS_REQUEST:
      return {
        ...state,
        fetchingAccounts: true,
        fetchingAccountsFailure: initialState.fetchingAccountsFailure,
        accountsFetched: initialState.accountsFetched,
      };
    case GET_COMMON_ACCOUNTS_SUCCESS:
      return {
        ...state,
        fetchingAccounts: false,
        allAccounts: action.payload,
        accountsFetched: true,
      };
    case GET_COMMON_ACCOUNTS_FAILURE:
      return {
        ...state,
        fetchingAccounts: false,
        fetchingAccountsFailure: true,
      };
    case DO_RESOURCES_EXIST_REQUEST:
      return {
        ...state,
        fetchingAccountPermissions: true,
        fetchingAccountPermissionsFailure:
          initialState.fetchingAccountPermissionsFailure,
      };
    case DO_RESOURCES_EXIST_SUCCESS:
      return {
        ...state,
        fetchingAccountPermissions: false,
        accountPermissions: action.payload,
      };
    case DO_RESOURCES_EXIST_FAILURE:
      return {
        ...state,
        fetchingAccountPermissions: false,
        fetchingAccountPermissionsFailure: true,
      };
    case SAVE_PAGINATION_PAGE_DETAILS:
      return {
        ...state,
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize,
      };
    case GET_ACCOUNT_PERMISSION_CFN_URL_REQUEST:
      return {
        ...state,
        fetchingCfnUrl: initialState.fetchingCfnUrl,
        fetchingCfnUrlFailed: initialState.fetchingCfnUrlFailed,
      };
    case GET_ACCOUNT_PERMISSION_CFN_URL_SUCCESS:
      return {
        ...state,
        cfnData: action.payload,
        fetchingCfnUrl: false,
      };
    case GET_ACCOUNT_PERMISSION_CFN_URL_FAILURE:
      return {
        ...state,
        cfnData: action.payload,
        fetchingCfnUrl: false,
        fetchingCfnUrlFailed: true,
      };
    case CLEAR_UPDATE_PERMISSION_DATA:
      return {
        ...state,
        cfnData: initialState.cfnData,
        fetchingCfnUrl: initialState.fetchingCfnUrl,
        fetchingCfnUrlFailed: initialState.fetchingCfnUrlFailed,
      };
    case SAVE_ADDED_ACTIONS:
      return {
        ...state,
        addedActions: action.payload,
      };
    case CLEAR_ADDED_ACTIONS:
      return {
        ...state,
        addedActions: initialState.addedActions,
      };
    case SAVE_ACTIONS_API_RESPONSE:
      return {
        ...state,
        actionsApiResponse: action.payload,
      };
    case SAVE_COMMON_FILTERS_DATA:
      return {
        ...state,
        commonFilters: action.payload,
      };
    case SET_SELECTED_REGION_FILTER:
      return {
        ...state,
        selectedRegions: action.payload,
      };
    case SET_SELECTED_PROJECT_FILTER:
      return {
        ...state,
        selectedProjects: action.payload,
      };

    case SET_SELECTED_ACCOUNT_FILTER:
      return {
        ...state,
        selectedAccountsData: action.payload,
      };

    case SET_SELECTED_ENVIRONMENT_FILTER:
      return {
        ...state,
        selectedEnvironments: action.payload,
      };

    case SET_SELECTED_FINDINGS_FILTER:
      return {
        ...state,
        debouncedFindingSearchValue: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default commons;
