import { combineReducers } from "redux";
import { createReducer } from "../../../utils/create-reducer";
import {
  COST_OPTIMIZATION_SUMMARY,
  RESOURCE_INSIGHT,
} from "../../../utils/action-names.json";

const costOptimizationSummary = createReducer(COST_OPTIMIZATION_SUMMARY);
const resourceInsight = createReducer(RESOURCE_INSIGHT);

export default combineReducers({
  costOptimizationSummary,
  resourceInsight,
});
