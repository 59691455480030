import i18n from "../config/locale/i18n";
import { getOrganizationDetails } from "../modules/organization/actions/organization";
import {
  addUsersToNewOrganizationApi,
  createOrganizationApi,
  customerPreferenceApi,
  deleteOrganizationApi,
  fetchOrganizationAccessListApi,
  getAllContextOrganizationsApi,
  getAllSettingsOrganizationsApi,
  getOrganizationDetailsApi,
  revokeOrganizationAccessApi,
  switchOrganizationContextApi,
  updateOrganizationDetailsApi,
} from "../modules/organization/services/organization";

import {
  ORG_MGR_CREATED,
  ORG_MGR_CREATING,
  ORG_MGR_DROPDOWN_LOADED,
  ORG_MGR_DROPDOWN_LOADING,
  ORG_MGR_EDIT,
  ORG_MGR_EDIT_LOADED,
  ORG_MGR_INVITED,
  ORG_MGR_LIST_LOADED,
  ORG_MGR_LIST_LOADING,
  ORG_MGR_ACCESS_LIST_CLEAR,
  ORG_MGR_ACCESS_LIST_REQUEST,
  ORG_MGR_ACCESS_LIST_SUCCESS,
  ORG_MGR_ACCESS_REVOKE_REQUEST,
  ORG_MGR_RESET,
  ORG_MGR_SWITCHED,
  ORG_MGR_SWITCHING,
  ORG_MGR_UPDATED,
  ORG_MGR_UPDATE_DETAILS_FORM,
  ORG_MGR_UPDATE_INVITES,
  ORG_MGR_USER_INVITED,
  ORG_MGR_DELETEING_ORG,
  ORG_MGR_DELETE_ORG,
  ORG_MGR_DELETE_ABORT,
  ORG_MGR_DELETED_ORG,
  ORG_MGR_ACCESS_REVOKE_RESET,
  ORG_MGR_ACCESS_REVOKE_SUCCESS,
  ORG_MGR_ACCESS_REVOKE,
  ORG_MGR_CREATING_FAILED,
  ORG_MGR_INVITING_USERS,
  ORG_MGR_SET_ACTIVE,
  ORG_MGR_SWITCHING_AFTER_CREATE,
  ORG_MGR_SET_ACTIVE_LOADING,
} from "../actions/org-manager-action-types";

import { getRefreshToken } from "../modules/authentication/actions/auth";

import { isArrayWithLength } from "../utils/array-methods";
import { postNotification } from "../utils/notification-message";
import { clearUtility } from "./layout";

import { FEATURE, FEATURES, FEATURE_PLANS, CURRENT_ORG } from "../utils/app-constants.json";
import { ORG_EDITING_VIEW } from "../modules/organizations-multi-tenancy/utils/constants";
import { tenantCategoryAPI } from "../modules/tenant-category/apiService/tenant-category-api-service";
import { getLoggedInUserDetails } from "../modules/authentication/actions/auth";

export const saveOrganizationDetails = (payload) => ({
  type: ORG_MGR_UPDATE_DETAILS_FORM,
  payload,
});

export const saveNewOrganizationInvites = (payload) => ({
  type: ORG_MGR_UPDATE_INVITES,
  payload,
});

export const singleUserInvitedSuccessfully = () => ({
  type: ORG_MGR_USER_INVITED,
});

export const allUserInvitedSuccessfully = () => ({
  type: ORG_MGR_INVITED,
});

export const resetCreateOrgFlow = () => ({
  type: ORG_MGR_RESET,
});
export const switchOrgAfterCreating = (payload) => ({
  type: ORG_MGR_SWITCHING_AFTER_CREATE,
  payload,
});

export const deleteOrganization = (payload) => ({
  type: ORG_MGR_DELETE_ORG,
  payload,
});

export const cancelDeleteOrganization = (payload) => ({
  type: ORG_MGR_DELETE_ABORT,
  payload,
});

export const organizationDeleted = (payload) => ({
  type: ORG_MGR_DELETED_ORG,
  payload,
});

export const showRevokeAccess = (payload) => ({
  type: ORG_MGR_ACCESS_REVOKE,
  payload,
});

export const cancelRevokeAction = (payload) => ({
  type: ORG_MGR_ACCESS_REVOKE_RESET,
  payload,
});

export const startInvitingUsers = (payload) => ({
  type: ORG_MGR_INVITING_USERS,
  payload,
});

export const getAllSwitchableOrganizations = () => async (dispatch) => {
  dispatch({
    type: ORG_MGR_DROPDOWN_LOADING,
  });

  getAllContextOrganizationsApi()
    .then((orgs) => {
      dispatch({
        type: ORG_MGR_DROPDOWN_LOADED,
        payload: orgs,
      });
    })
    .catch((error) => {
      const message =
        JSON.parse(error.message).data.ErrorMessage ||
        i18n.t("multiTenancy:err.contextSwitchOrg");
      dispatch(postNotification(message, "danger"));
    });
};

export const getActiveOrganization = () => async (dispatch, getState) => {
  const {
    auth: { usersActiveOrganizationId },
  } = getState();

  dispatch({
    type: ORG_MGR_SET_ACTIVE_LOADING,
  });

  await getOrganizationDetailsApi(usersActiveOrganizationId).then((res) => {
    localStorage.setItem(CURRENT_ORG, JSON.stringify(res));
    dispatch({
      type: ORG_MGR_SET_ACTIVE,
      payload: res,
    });
  });
};

export const switchOrganizationContext =
  (organisationID) => async (dispatch) => {
    dispatch({
      type: ORG_MGR_SWITCHING,
    });
    switchOrganizationContextApi(organisationID)
      .then((res) => {
        const successcallback = () => {
          dispatch({
            type: ORG_MGR_SWITCHED,
          });
        };
        dispatch(getLoggedInUserDetails(successcallback));
        dispatch(getRefreshToken());
      })
      .catch((error) => {
        const message =
          JSON.parse(error.message).data.ErrorMessage ||
          i18n.t("multiTenancy:err.contextSwitchFailed");
        dispatch(postNotification(message, "danger"));
      });
  };

export const getAllSettingsOrganizations = () => async (dispatch) => {
  dispatch({
    type: ORG_MGR_LIST_LOADING,
  });

  getAllSettingsOrganizationsApi()
    .then((res) => {
      dispatch({
        type: ORG_MGR_LIST_LOADED,
        payload: res,
      });
    })
    .catch((error) => {
      const message =
        JSON.parse(error.message).data.ErrorMessage ||
        i18n.t("multiTenancy:err.settingListFailed");
      dispatch(postNotification(message, "danger"));
    });
};

export const addUsersToNewOrganization =
  (showInDetailsView = false) =>
    async (dispatch, getState) => {
      let { organizationManager, organization } = getState();

      let {
        isEditMode,
        isEditingFromDetails,
        createOrganization: form,
        organizationAccessList,
      } = organizationManager;

      let usersToBeInvited = form.inviteUsers;
      let orgId = "";
      let usersToBeRemoved = form.unInviteUsers;
      let inviteApiResponses = [];
      let existingUserIds = [];

      if (showInDetailsView) {
        const { organizationDetails } = organization;
        orgId = organizationDetails.ID;
      } else {
        orgId = form.createdOrganization;
      }

      if (isArrayWithLength(organizationAccessList)) {
        // using this to check if newly added organizations
        // exists in previously added list so we can skip api call
        existingUserIds = organizationAccessList.map((user) => user.ID);
      }

      if (isArrayWithLength(usersToBeInvited)) {
        usersToBeInvited = usersToBeInvited.filter(
          (user, index, self) =>
            index === self.findIndex((u) => u.value === user.value) // value is userId
        );
      }

      dispatch(startInvitingUsers());

      for (const user of usersToBeInvited) {
        if (existingUserIds.includes(user.value)) {
          inviteApiResponses.push({ user, data: {} });
          continue;
        }

        await addUsersToNewOrganizationApi({
          userId: user.value, // user id
          orgId,
        })
          .then((response) => {
            let { data } = response;

            inviteApiResponses.push({
              user,
              data,
            });
          })
          .catch((error) => {
            const message =
              JSON.parse(error.message).data.ErrorMessage ||
              i18n.t("multiTenancy:err.addUserFailed");
            dispatch(postNotification(message, "danger"));
          });
      }

      let removeUserResponses = [];

      if (isEditMode) {
        for (let user of usersToBeRemoved) {
          await revokeOrganizationAccessApi({ userId: user.ID, orgId })
            .then((resp) => {
              let { data } = resp;
              removeUserResponses.push({
                user,
                data,
              });
            })
            .catch((error) => {
              const message =
                JSON.parse(error.message).data.ErrorMessage ||
                i18n.t("multiTenancy:err.revokeAccessFailed");
              dispatch(postNotification(message, "danger"));
            });
        }
      }

      if (
        isArrayWithLength(inviteApiResponses) ||
        isArrayWithLength(removeUserResponses)
      ) {
        dispatch(allUserInvitedSuccessfully());
      }

      if (!isEditMode) {
        dispatch(fetchOrganizationAccessList(orgId));

        setTimeout(() => {
          dispatch(getAllSwitchableOrganizations());
        }, 2000);
      } else {
        dispatch(singleUserInvitedSuccessfully());
      }

      if (isEditingFromDetails) {
        dispatch(getOrganizationDetails(orgId));
        dispatch(getAllSettingsOrganizations());

        dispatch(fetchOrganizationAccessList(orgId));
      } else {
        dispatch(getAllSettingsOrganizations());
      }
    };

export const createOrganiaztion = (payload) => async (dispatch) => {
  dispatch({
    type: ORG_MGR_CREATING,
  });

  createOrganizationApi(payload)
    .then((res) => {
      dispatch({
        type: ORG_MGR_CREATED,
        payload: res.ID,
      });

      dispatch(addUsersToNewOrganization());
    })
    .catch((error) => {
      dispatch({
        type: ORG_MGR_CREATING_FAILED,
      });
      const message =
        JSON.parse(error.message).data.ErrorMessage ||
        i18n.t("multiTenancy:err.createFailed");
      dispatch(postNotification(message, "danger"));
    });
};

export const upgradePlan = (orgId, dispatch) => {
  let params = {
    PreferenceType: FEATURES,
    Preference: FEATURE_PLANS.AUTOMATED_CLOUD_OPS,
  };

  return customerPreferenceApi({
    orgId,
    params,
  });
};

export const updateOrganiaztion = (params) => async (dispatch, getState) => {
  let {
    organizationManager: {
      createOrganization: {
        createdOrganization: orgId,
        details: { Feature, previousFeature },
      },
      isEditingFromDetails,
      isEditMode,
    },
    auth: { usersActiveOrganizationId },
  } = getState();

  dispatch({
    type: ORG_MGR_CREATING,
  });

  let payload = { ...params };

  let orgUpdated = await updateOrganizationDetailsApi({
    orgId,
    params: payload,
  })
    .then((res) => res)
    .catch((error) => {
      const message =
        JSON.parse(error.message).data.ErrorMessage ||
        i18n.t("multiTenancy:err.updateFailed");
      dispatch(postNotification(message, "danger"));

      dispatch(resetCreateOrgFlow());
      dispatch(clearUtility());

      return false;
    });

  const updateGeneralOrg = orgId === usersActiveOrganizationId;

  if (orgUpdated) {
    if (Feature !== previousFeature) {
      await upgradePlan(orgId, dispatch).catch((error) => {
        const message =
          JSON.parse(error.message).data.ErrorMessage ||
          i18n.t("multiTenancy:err.updateFailed");
        dispatch(postNotification(message, "danger"));
      });
    }

    if (isEditMode && isEditingFromDetails) {
      if (updateGeneralOrg) {
        dispatch(getActiveOrganization());
      } else {
        dispatch(getOrganizationDetails(orgId));
      }
    }

    dispatch(getAllSettingsOrganizations());
    dispatch(getAllSwitchableOrganizations());

    dispatch({
      type: ORG_MGR_UPDATED,
    });
  }

  if (!updateGeneralOrg) {
    // don't clear if edited from general org page
    dispatch(resetCreateOrgFlow());
  }

  dispatch(clearUtility());
};

export const editOrganization =
  (organization, view, isOnlyFeatureSelection = false) =>
    async (dispatch, getState) => {
      dispatch({
        type: ORG_MGR_EDIT,
        payload: organization.ID,
      });

      let {
        users: { usersSearchList },
        auth: { userId: authUserId },
        [tenantCategoryAPI.reducerPath]: tenantCategoryData,
      } = getState();

      let editableOrganization = { ...organization };

      let Owner = [];

      let userId = editableOrganization
        ? editableOrganization.OwnerKey || editableOrganization.Owner
        : "";

      if (usersSearchList && userId in usersSearchList) {
        let user = usersSearchList[userId];
        let label =
          authUserId === user.ID
            ? i18n.t("multiTenancy:listitem.me", {
              userName: user.Name,
            })
            : `${user.Name} (${user.Email})`;
        Owner = {
          DisplayName: user.Name,
          Name: user.Name,
          Email: user.Email,
          value: user.ID,
          label,
          Metadata: null,
          UserId: user.ID,
        };
      }

      let isEditingFromDetails = view === ORG_EDITING_VIEW;

      // our current org list has org's without Feature
      let Feature =
        editableOrganization && FEATURE in editableOrganization
          ? editableOrganization.Feature
          : null;

      const categoryData =
        Object.values(tenantCategoryData.queries)?.find(
          ({ endpointName }) =>
            endpointName ===
            tenantCategoryAPI.endpoints.getTenantCategoryList.name
        )?.data ?? [];

      dispatch({
        type: ORG_MGR_EDIT_LOADED,
        payload: {
          isEditingFromDetails,
          isOnlyFeatureSelection,
          details: {
            Name: editableOrganization.Name,
            Description: editableOrganization.Description,
            Owner,
            Feature,
            previousFeature: Feature,
            Category:
              editableOrganization.CategoryId &&
                categoryData?.find(
                  ({ Id }) => Id === editableOrganization.CategoryId
                )
                ? {
                  label:
                    categoryData?.find(
                      ({ Id }) => Id === editableOrganization.CategoryId
                    )?.Name ?? "",
                  value: editableOrganization.CategoryId,
                }
                : null,
          },
        },
      });
    };

export const fetchOrganizationAccessList = (orgId) => (dispatch) => {
  dispatch({
    type: ORG_MGR_ACCESS_LIST_REQUEST,
  });
  fetchOrganizationAccessListApi(orgId)
    .then((res) => {
      dispatch(singleUserInvitedSuccessfully());
      dispatch({
        type: ORG_MGR_ACCESS_LIST_SUCCESS,
        payload: res.data,
      });
    })
    .catch((error) => {
      const message =
        JSON.parse(error.message).data.ErrorMessage ||
        i18n.t("multiTenancy:err.orgAccessListFailed");
      dispatch(postNotification(message, "danger"));
    });
};

export const revokeOrganizationAccess =
  ({ user, orgId }) =>
    (dispatch) => {
      dispatch({
        type: ORG_MGR_ACCESS_REVOKE_REQUEST,
        payload: user,
      });
      revokeOrganizationAccessApi({ userId: user.ID, orgId })
        .then((resp) => {
          dispatch({
            type: ORG_MGR_ACCESS_REVOKE_SUCCESS,
            payload: user,
          });
        })
        .catch((error) => {
          const message =
            JSON.parse(error.message).data.ErrorMessage ||
            i18n.t("multiTenancy:err.revokeAcceeUserFailed");
          dispatch(postNotification(message, "danger"));
        });
    };

export const clearOrganizationAccessList = () => (dispatch) => {
  dispatch({
    type: ORG_MGR_ACCESS_LIST_CLEAR,
  });
};

export const attemptDeleteOrganization = () => (dispatch, getState) => {
  dispatch({
    type: ORG_MGR_DELETEING_ORG,
  });

  const { activity } = getState().organizationManager;
  let { deletingOrganization: organisation } = activity.delete;

  deleteOrganizationApi(organisation.ID)
    .then((res) => {
      let { data } = res;
      dispatch(organizationDeleted(data));
    })
    .catch((error) => {
      dispatch(cancelDeleteOrganization());
      const message =
        JSON.parse(error.message).data.ErrorMessage ||
        i18n.t("multiTenancy:err.orgDeleteFailed");
      dispatch(postNotification(message, "danger"));
    });
};
