import i18n from "../../config/locale/i18n";
import { CHECKS_STATUS } from "../../utils/app-constants.json";
import circleMinusBlue from "../../images/best-practices-icons/circle-minus-blue-small.svg";

import ClockIcon from "../../images/best-practices-icons/clock.svg";
import DangerIcon from "../../images/best-practices-icons/circle-xmark.svg";
import WarningIcon from "../../images/best-practices-icons/triangle-exclamation.svg";
import CircleCheck from "../../images/best-practices-icons/circle-check.svg";
import CircleMinus from "../../images/best-practices-icons/circle-minus.svg";

import DangerRedIcon from "../../images/best-practices-icons/circle-xmark-red.svg";
import WarningYellowIcon from "../../images/best-practices-icons/triangle-exclamation-yellow.svg";
import CircleGreenCheck from "../../images/best-practices-icons/circle-check-green.svg";
import CircleBlueMinus from "../../images/best-practices-icons/circle-minus-blue.svg";

export const reviewDetailFields = [
  {
    type: "text",
    required: true,
    key: "field-label-1",
    apiKey: "Name",
    value: "",
    id: "field-label-1",
    label: i18n.t("assessments:fieldLabel1"),
    reviewLabel: i18n.t("assessments:reviewName"),
  },
  {
    type: "text",
    required: true,
    key: "field-label-2",
    apiKey: "Description",
    value: "",
    id: "field-label-2",
    label: i18n.t("assessments:fieldLabel2"),
    reviewLabel: i18n.t("assessments:reviewDescription"),
  },
  {
    type: "text",
    required: true,
    key: "field-label-3",
    apiKey: "Owner",
    value: "",
    id: "field-label-3",
    label: i18n.t("assessments:fieldLabel3"),
    reviewLabel: i18n.t("assessments:reviewOwner"),
  },
  {
    type: "text",
    required: true,
    key: "field-label-4",
    apiKey: "Lens",
    value: "",
    id: "field-label-4",
    label: i18n.t("assessments:fieldLabel4"),
    reviewLabel: i18n.t("assessments:reviewLens"),
  },
  {
    type: "text",
    required: true,
    key: "field-label-5",
    apiKey: "ArchitecturalDesignUrl",
    value: "",
    id: "field-label-5",
    label: i18n.t("assessments:fieldLabel5"),
    reviewLabel: i18n.t("assessments:reviewArchitecturalDesignUrl"),
  },
];

export const CHOICE_SELECTED = "SELECTED";
export const CHOICE_NOT_APPLICABLE = "NOT_APPLICABLE";
export const REASON_NONE = "NONE";
export const NONE_OF_THESE = "None of these";
export const UNSELECTED = "UNSELECTED";

export const SCOPE_TYPE = {
  ACCOUNT: "ACCOUNT",
  PROJECT: "PROJECT",
};
export const ENV_TYPES = ["PRODUCTION", "PREPRODUCTION"];
export const LIMITED_REGIONS = [
  "us-east-1",
  "us-east-2",
  "ap-south-1",
  "ap-northeast-1",
];

export const PILLAR_NAME_MAP = {
  security: i18n.t("assessments:pillar.name.security"),
  performance: i18n.t("assessments:pillar.name.performance"),
  reliability: i18n.t("assessments:pillar.name.reliability"),
  costOptimization: i18n.t("assessments:pillar.name.costOptimization"),
  sustainability: i18n.t("assessments:pillar.name.sustainability"),
  operationalExcellence: i18n.t(
    "assessments:pillar.name.operationalExcellence"
  ),
};

export const ASSESSMENT_TABS = {
  IN_PROGRESS: i18n.t("assessments:tab.inProgress"),
  COMPLETED: i18n.t("assessments:tab.completed"),
};

export const FINDING_API_PAGE_LIMIT = 25;

export const FINDING_COLUMN_IDS = {
  RESOURCE_ID: "ResourceId",
  PILLAR: "PillarId",
  SEVERITY: "Severity",
  STATUS: "Status",
  QUESTION: "Question",
  CHECK_TITLE: "Title",
  ACCOUNT_NAME: "AccountName",
  ACTIONS: "Actions",
  RESOURCE_TYPE: "ResourceType",
  CHECK_DESCRIPTION: "Description",
  ACCOUNT_NUMBER: "AccountNumber",
  REGION_CODE: "RegionCode",
  BEST_PRACTICE_RISK: "BestPracticeRisk",
  BEST_PRACTICE: "BestPractice",
  RISK_EXPOSURE: "RiskExposure",
};

export const findingFilterMap = {
  [FINDING_COLUMN_IDS.RESOURCE_ID]: "ResourceId",
  [FINDING_COLUMN_IDS.PILLAR]: "PillarIds",
  [FINDING_COLUMN_IDS.SEVERITY]: "Severity",
  [FINDING_COLUMN_IDS.STATUS]: "Status",
  [FINDING_COLUMN_IDS.QUESTION]: "QuestionIds",
  [FINDING_COLUMN_IDS.CHECK_TITLE]: "Title",
  [FINDING_COLUMN_IDS.ACCOUNT_NAME]: "AccountNumber",
  [FINDING_COLUMN_IDS.RESOURCE_TYPE]: "ResourceType",
  [FINDING_COLUMN_IDS.REGION_CODE]: "RegionCode",
  [FINDING_COLUMN_IDS.BEST_PRACTICE_RISK]: "BestPracticeRisk",
  [FINDING_COLUMN_IDS.BEST_PRACTICE]: "BestPracticeIds",
  KEYWORD_SEARCH: "KeywordSearch",
};

export const allowedRowGroups = [
  FINDING_COLUMN_IDS.PILLAR,
  FINDING_COLUMN_IDS.SEVERITY,
  FINDING_COLUMN_IDS.BEST_PRACTICE_RISK,
];

export const QUERY_PARAMS = {
  SORT_BY: "sortBy",
  SORT_ORDER: "sortOrder",
  PILLAR_ID: "pillarId",
  QUESTION_ID: "questionId",
  STATUS: "status",
  RESOURCE_ID: "resourceId",
  SEVERITY: "severity",
  CHECK_TITLE: "checkTitle",
  ACCOUNT_NAME: "accountName",
  REGION: "region",
  RESOURCE_TYPE: "resourceType",
  ACCOUNT_NUMBER: "accountNumber",
  QUESTION: "question",
  MILESTONE_NAME: "name",
  BEST_PRACTICE_ID: "bestPracticeId",
  FIDNINGS_TYPE: "findingsType",
};

export const pillarFilterOptions = Object.keys(PILLAR_NAME_MAP).map(
  (pillarId) => ({
    label: PILLAR_NAME_MAP[pillarId],
    value: pillarId,
  })
);

export const severityFilterOptions = [
  {
    value: "Critical",
    label: i18n.t("assessments:insightList.severity.critical"),
  },
  {
    value: "High",
    label: i18n.t("assessments:insightList.severity.high"),
  },
  {
    value: "Medium",
    label: i18n.t("assessments:insightList.severity.medium"),
  },
  {
    value: "Low",
    label: i18n.t("assessments:insightList.severity.low"),
  },
  {
    value: "Informational",
    label: i18n.t("assessments:insightList.severity.informational"),
  },
];

export const statusFilterOptions = [
  {
    value: CHECKS_STATUS.FAILED,
    label: i18n.t("assessments:pillar.failedChecks"),
  },
  {
    value: CHECKS_STATUS.ERROR,
    label: i18n.t("assessments:pillar.errorChecks"),
  },
  {
    value: CHECKS_STATUS.SUPPRESSED,
    label: i18n.t("assessments:pillar.suppressedChecks"),
  },
  {
    value: CHECKS_STATUS.PASSED,
    label: i18n.t("assessments:pillar.passedChecks"),
  },
];

export const COMPLETED = "COMPLETED";
export const FAILED = "failed";
export const PILLAR = "Pillar";
export const WORKLOAD = "Workload";
export const QUESTION = "Question";
export const OPERATIONALEXCELLENCE = "operationalExcellence";
export const SECURITY = "security";
export const RELIABILITY = "reliability";
export const PERFORMANCE = "performance";
export const COSTOPTIMIZATION = "costOptimization";
export const SUSTAINABILITY = "sustainability";
export const UNANSWERED = "UNANSWERED";
export const ALL = "All";
export const TOP5VIOLATINGCHECKS = "Top5ViolatingChecks";
export const FAILEDFINDINGSBYPILLAR = "FailedFindingsByPillar";
export const TOTALFINDINGSBREAKDOWN = "TotalFindingsBreakdown";
export const TOP5VIOLATINGRESOURCES = "Top5ViolatingResources";
export const FAILEDFINDINGSBYRESOURCETYPE = "FailedFindingsByResourceType";

export const MILESTONE_NAME_COL_ID = "MilestoneName";

export const NOTES_MAX_LENGTH = 2084;

export const ASSESSMENT_QUESTION_RISK_STATUS_MAP = {
  HIGH: {
    text: i18n.t("assessments:question.risk.high"),
    icon: "/images/icons/wafrBestPractices/circle-xmark.svg",
    className: "highRisk",
  },
  MEDIUM: {
    text: i18n.t("assessments:question.risk.medium"),
    icon: "/images/icons/wafrBestPractices/triangle-exclamation.svg",
    className: "mediumRisk",
  },
  NONE: {
    text: i18n.t("assessments:question.risk.none"),
    icon: "/images/icons/wafrBestPractices/circle-check.svg",

    className: "noneRisk",
  },
  NOT_APPLICABLE: {
    text: i18n.t("assessments:question.risk.notApplicable"),
    icon: circleMinusBlue,
    className: "notApplicableRisk",
  },
  UNANSWERED: {
    text: i18n.t("assessments:question.risk.unanswered"),
    icon: "/images/icons/wafrBestPractices/clock.svg",
    className: "unansweredRisk",
  },
};

export const RISKDATA = [
  {
    id: 1,
    text: i18n.t("assessments:question.risk.unanswered"),
    icon: ClockIcon,
    activeIcon: ClockIcon,
    label: "UNANSWERED",
  },
  {
    id: 2,
    text: i18n.t("assessments:question.risk.high"),
    icon: DangerIcon,
    activeIcon: DangerRedIcon,
    label: "HIGH",
  },
  {
    id: 3,
    text: i18n.t("assessments:question.risk.medium"),
    icon: WarningIcon,
    activeIcon: WarningYellowIcon,
    label: "MEDIUM",
  },
  {
    id: 4,
    text: i18n.t("assessments:question.risk.none"),
    icon: CircleCheck,
    activeIcon: CircleGreenCheck,
    label: "NONE",
  },
  {
    id: 5,
    text: i18n.t("assessments:question.risk.notApplicable"),
    icon: CircleMinus,
    activeIcon: CircleBlueMinus,
    label: "NOT_APPLICABLE",
  },
];

export const SWITCHER_ALL_FINDINGS = "ALL_FINDINGS";
export const SWITCHER_UNIQUE_FINDINGS = "UNIQUE_FINDINGS";

export const FINDINGS_SWITCHER_ITEMS = [
  {
    id: SWITCHER_ALL_FINDINGS,
    text: i18n.t("assessments:switcher.allFindings"),
    label: i18n.t("assessments:switcher.allFindings"),
    value: SWITCHER_ALL_FINDINGS,
  },
  {
    id: SWITCHER_UNIQUE_FINDINGS,
    text: i18n.t("assessments:switcher.uniqueFindings"),
    label: i18n.t("assessments:switcher.uniqueFindings"),
    value: SWITCHER_UNIQUE_FINDINGS,
  },
];
