import moment from "moment";
import React, { Fragment } from "react";
import Datetime from "react-datetime";
import { UncontrolledTooltip } from "reactstrap";

export const isDateValid = (date) => moment(date).isValid();
export const formatDateToUTC = (
  date,
  includeSeconds = true,
  showUTC = true
) => {
  return date && isDateValid(date)
    ? includeSeconds
      ? `${moment(date).format("DD/MMM/YY HH:mm:ss")}${showUTC ? " UTC" : ""}`
      : `${moment(date).format("DD/MMM/YY HH:mm")}${showUTC ? " UTC" : ""}`
    : "-";
};

export const formatDateToLocalTimeZone = ({
  format = "DD MMM YYYY, HH:mm",
  date,
}) => {
  const utcDate = date ? moment.utc(date) : null;
  const localDate = utcDate ? moment(utcDate).local() : null;
  return localDate ? localDate.format(format) : "";
};

export const formatDateWithTime = (date) => {
  return moment(date).isValid()
    ? moment(date).format("DD MMM YYYY HH:mm A")
    : "-";
};

export const formatDateStartWithMonth = (date) => {
  return date ? moment(date).format("MM/DD/YY HH:MM") : "-";
};

export const formatDateWithoutZone = (date, includeSeconds = true) => {
  if (moment(date).isValid()) {
    return date && includeSeconds
      ? moment(date).format("DD-MMM-YY HH:mm:ss")
      : moment(date).format("DD-MMM-YY HH:mm");
  } else return "-";
};

export const getCurrentDateTime = () => {
  return moment().toDate();
};

export const getCurrentDateTimeUTC = () => {
  return `${moment.utc().format("DD/MMM/YY HH:mm")} UTC`;
};

export const getUTCTime = () => {
  const localDate = new Date();
  const utcTime = moment.utc(localDate).format();
  const utc = utcTime.substring(11);
  return utc.slice(0, -1);
};

const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

export const getDaysBetweenTwoDate = (date1, date2) => {
  const firstDate = new Date(date1);
  const secondDate = new Date(date2);
  const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
  return diffDays;
};

export const getNumberofDays = (date) => {
  const firstDate = new Date(date);
  const today = getCurrentDateTime();
  const day = Math.round(Math.abs((firstDate - today) / oneDay));
  return day;
};

export const getHoursDiffrenceFromCurrent = (date) => {
  let now = moment.utc(new Date());
  let passedDate = moment.utc(date);
  return now.diff(passedDate, "hours");
};

export const getDateWithDays = (days) => {
  let date = new Date();
  date.setDate(date.getDate() + days);
  return date;
};

export const formatDateYMD = (date, includeSeconds = true) => {
  return date && includeSeconds
    ? moment(date).format("YYYY-MM-DD HH:mm:ss")
    : moment(date).format("YYYY-MM-DD HH:mm");
};

export const humanizedDateFormat = (date) => {
  return date.format("DD MMM YYYY");
};

export const humanizedDateFormatFromString = (dateString) => {
  return humanizedDateFormat(moment(new Date(dateString)));
};

export const updatedAtFormatter = (cell) => {
  if (!cell) return "";
  const date = moment.utc(cell);
  const dateTime = formatDateWithTime(date);
  const statusTime = date.isValid() ? moment(date).fromNow() : "-";
  const targetId = `date-time-${date.valueOf()}`;
  return (
    <Fragment key={targetId}>
      <span id={targetId}>{statusTime}</span>
      <UncontrolledTooltip placement="bottom" target={targetId}>
        {dateTime}
      </UncontrolledTooltip>
    </Fragment>
  );
};

export const formatDateByDaysAndTime = (cell) => {
  if (!cell) return "";
  const todaysDate = moment.utc();
  const date = moment.utc(cell);
  const dateTimeForLastRun = formatDateStartWithMonth(date);
  const numberOfDays = todaysDate.diff(date, "days");
  let dateWithNumOfDays = {
    dateTimeForLastRun,
    numberOfDays,
  };
  return dateWithNumOfDays;
};

export const updatedAtProjectStorageFormatter = (cell) => {
  if (!cell) return "";
  const date = moment.utc(cell);
  const dateTime = formatDateWithTime(date);
  const statusTime = moment(date).fromNow();
  const targetId = `date-time-${date.valueOf()}`;
  return (
    <>
      <span id={targetId}>{statusTime}</span>
      <UncontrolledTooltip placement="bottom" target={targetId}>
        {dateTime}
      </UncontrolledTooltip>
    </>
  );
};

export const sortDate = (previousDate, nextDate, order) => {
  return order === "asc"
    ? new Date(previousDate) - new Date(nextDate)
    : new Date(nextDate) - new Date(previousDate);
};

export const getDaysFromCurrentDate = (days) => {
  const date = new Date();
  date.setDate(date.getDate() + days);
  return date;
};

export const addDaysFromDate = (date, day) => {
  const newdate = new Date(date);
  newdate.setDate(newdate.getDate() + day);
  return newdate;
};

export const formatDateWithMMMDDYYYY = (date) => {
  return moment(date).format("MMMM DD, YYYY");
};

export const getNextMonthFromCurrentDate = () => {
  let current;
  const now = new Date();
  if (now.getMonth() === 11) {
    current = new Date(now.getFullYear() + 1, 0, 0);
  } else {
    current = new Date(now.getFullYear(), now.getMonth() + 1, 0);
  }
  return current;
};

export const getNextYearFromCurrentDate = () => {
  const now = new Date();
  const current = new Date(now.getFullYear() + 1, now.getMonth(), 0);
  return current;
};

export const getCurrentYear = (formatStr = "YYYY") =>
  moment().format(formatStr);

export const getCurrentDate = (format = "YYYY-MM-DD") => {
  return Datetime.moment().format(format); // Format: 2021-05-30
};

export const getCurrentDateMinusDays = (format = "YYYY-MM-DD", days = 1) => {
  return Datetime.moment().subtract(days, "days").format(format); // Format: 2021-05-30
};

export const getCurrentMonthStartDate = (format = "YYYY-MM-DD") => {
  const currentDate = moment().date();
  let date = Datetime.moment();
  if (currentDate === 1) {
    date = Datetime.moment().subtract(1, "months");
  }
  return date.startOf("month").format(format); // Format: 2021-05-01
};

export const humanizedDateFormatWithOrdinal = (date) => {
  if (moment(date).isValid()) {
    return moment(date).format("Do MMM YYYY");
  } else return "-";
};

export const getDate = (date) => {
  if (moment(date).isValid()) {
    return moment(date).toDate();
  }
  return moment().toDate();
};

export const getDateTime = (dateTime) => {
  if (moment(dateTime).isValid()) {
    return moment(dateTime);
  }
  return moment();
};

export const areDateEqual = (date1, date2) => {
  return moment(date1).isSame(date2, "day");
};

export const isFirstDateGreaterThanSecondDate = (date1, date2) => {
  return moment(date1).isAfter(date2, "day");
};

export const isFirstDateBeforeSecondDate = (date1, date2) => {
  return moment(date1).isBefore(date2, "day");
};

export const getDateFromOffset = (date, days) => {
  let result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const dataGridDateFormatter = ({ value }) => {
  return moment(value).isValid()
    ? formatDateToLocalTimeZone({ date: value, format: "DD/MMM/YY" })
    : "-";
};

export const isValidDate = (date) => {
  return moment(date).isValid();
};

export const getMonthOptions = (limit = 24, currentMonthNotNeeded = false) => {
  const options = [];
  const currentMonth = moment();
  for (let i = currentMonthNotNeeded ? 1 : 0; i < limit; i++) {
    const month = currentMonth.clone().subtract(i, "months");
    options.push({
      label: month.format("MMMM YYYY"),
      value: month.format("MMMM YYYY"),
    });
  }
  return options;
};

export const isPreviousMonth = (givenDate, format = "DD/MM/YYYY") => {
  const momentDate = moment(givenDate, format);
  const currentDate = moment();
  const previousMonthDate = currentDate.clone().subtract(1, "months");
  return (
    momentDate.isSame(previousMonthDate, "month") &&
    momentDate.isSame(previousMonthDate, "year")
  );
};

export const isCurrentMonth = (givenDate, format = "DD/MM/YYYY") => {
  const momentDate = moment(givenDate, format);
  const currentDate = moment();
  return (
    momentDate.isSame(currentDate, "month") &&
    momentDate.isSame(currentDate, "year")
  );
};
