import { createApi } from "@reduxjs/toolkit/query/react";
import { WEB_REQUEST_TYPE } from "../../../../../helpers/apiService";
import { axiosBaseQuery } from "../../../../../config/customBaseQuery";
import {
  FINDINGS_SUMMARY_BY_SEVERITY,
  FINDING_SUMMARY,
  WAR_ASSESSMENT_RESOURCE_TYPES,
  WAR_ASSESSMENT_TOP_CHECKS,
  WAR_ASSESSMENT_TOP_RESOURCES,
  WAR_ASSESSMENT_WORKLOAD_BASE_URL,
} from "../../../../../config/service";
import { isEqual } from "lodash";

export const assessmentInsightsAPI = createApi({
  tagTypes: ["AssessmentInsightsData"],
  reducerPath: "assessmentInsightsAPI",
  baseQuery: axiosBaseQuery(),
  credentials: "true",
  endpoints: (builder) => ({
    getTotalFindingsBreakdown: builder.query({
      query: ({ workloadId, param }) => {
        const queryParams = new URLSearchParams({
          Filters: JSON.stringify({
            Status: "Failed",
            Card: "Workload",
            ...param,
          }),
        }).toString();
        return {
          url: `${WAR_ASSESSMENT_WORKLOAD_BASE_URL}${workloadId}${FINDING_SUMMARY}?${queryParams}`,
          method: WEB_REQUEST_TYPE.GET,
        };
      },
      providesTags: [
        { type: "AssessmentInsightsData", id: "assessmentInsights" },
      ],
    }),
    getTotalFailedFindingsByPillar: builder.query({
      query: ({ workloadId, param }) => {
        const queryParams = new URLSearchParams({
          Filters: JSON.stringify({
            ...param,
          }),
        }).toString();
        return {
          url: `${WAR_ASSESSMENT_WORKLOAD_BASE_URL}${workloadId}${FINDINGS_SUMMARY_BY_SEVERITY}?${queryParams}`,
          method: WEB_REQUEST_TYPE.GET,
        };
      },
      providesTags: [
        { type: "AssessmentInsightsData", id: "assessmentInsights" },
      ],
    }),
    getFindingsFailedByResourceType: builder.query({
      query: ({ workloadId, param }) => {
        const queryParams = new URLSearchParams({
          Filters: JSON.stringify({
            Status: "Failed",
            Dimension: "ResourceType",
            ...param,
          }),
        }).toString();
        return {
          url: `${WAR_ASSESSMENT_WORKLOAD_BASE_URL}${workloadId}${FINDINGS_SUMMARY_BY_SEVERITY}?${queryParams}`,
          method: WEB_REQUEST_TYPE.GET,
        };
      },
      providesTags: [
        { type: "AssessmentInsightsData", id: "assessmentInsights" },
      ],
    }),
    getTopFiveViolatingResources: builder.query({
      query: ({ workloadId, param }) => {
        const queryParams = new URLSearchParams({
          Filters: JSON.stringify({ Status: "Failed", ...param }),
        }).toString();
        return {
          url: `${WAR_ASSESSMENT_WORKLOAD_BASE_URL}${workloadId}${WAR_ASSESSMENT_TOP_RESOURCES}?${queryParams}`,
          method: WEB_REQUEST_TYPE.GET,
        };
      },
      providesTags: [
        { type: "AssessmentInsightsData", id: "assessmentInsights" },
      ],
    }),
    getTopFiveChecks: builder.query({
      query: ({ workloadId, param }) => {
        const queryParams = new URLSearchParams({
          Filters: JSON.stringify({ Status: "Failed", ...param }),
        }).toString();
        return {
          url: `${WAR_ASSESSMENT_WORKLOAD_BASE_URL}${workloadId}${WAR_ASSESSMENT_TOP_CHECKS}?${queryParams}`,
          method: WEB_REQUEST_TYPE.GET,
        };
      },
      providesTags: [
        { type: "AssessmentInsightsData", id: "assessmentInsights" },
      ],
    }),
    getInsightResourceTypes: builder.query({
      query: ({ workloadId, queryParams }) => {
        return {
          url: `${WAR_ASSESSMENT_WORKLOAD_BASE_URL}${workloadId}${WAR_ASSESSMENT_RESOURCE_TYPES}?${queryParams}`,
          method: WEB_REQUEST_TYPE.GET,
        };
      },
      refetchOnMountOrArgChange: true,
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const { workloadId } = queryArgs;
        return `wafr-insight-resource-types-${workloadId}-${endpointName}`;
      },
      merge: (currentCache, newItems, { arg: { queryParams } }) => {
        const newSearchTerm =
          new URLSearchParams(queryParams).get("Filters") || "";
        const currentSearchTerm = currentCache?.searchTerm || "";

        if (newSearchTerm !== currentSearchTerm) {
          return {
            Items: newItems.Items,
            Offset: newItems.Offset,
            searchTerm: newSearchTerm,
          };
        }
        const itemsMap = new Map();
        [currentCache?.Items, newItems?.Items].forEach((array) => {
          array?.forEach((item) => itemsMap.set(item.value, item));
        });
        return {
          ...currentCache,
          Items: Array.from(itemsMap.values()),
          Offset: newItems.Offset,
          searchTerm: newSearchTerm,
        };
      },
      forceRefetch({ currentArg, previousArg }) {
        return !isEqual(currentArg, previousArg);
      },
      transformResponse: (response, meta, arg) => {
        const newSearhTerm =
          new URLSearchParams(arg.queryParams).get("Filters") || "";
        return {
          Items:
            response?.Items?.map((resourceType) => ({
              label: resourceType,
              value: resourceType,
            })) || [],
          Offset: response?.Offset,
          searchTerm: newSearhTerm,
        };
      },
    }),
  }),
});

export const {
  useGetTotalFindingsBreakdownQuery,
  useGetTotalFailedFindingsByPillarQuery,
  useGetFindingsFailedByResourceTypeQuery,
  useGetTopFiveViolatingResourcesQuery,
  useGetTopFiveChecksQuery,
  useGetInsightResourceTypesQuery,
} = assessmentInsightsAPI;
