import { createApi } from "@reduxjs/toolkit/query/react";
import { WEB_REQUEST_TYPE } from "../../../helpers/apiServiceHelper";
import { axiosBaseQuery } from "../../../config/customBaseQuery";
import { CUSTOM_LOGO_PATH } from "../../../utils/route-paths.json";
import { getLoggedInUserAccessToken } from "../../../helpers/account";
import { CUSTOMER_ACCOUNT_LOGO } from "../../../config/service";

export const logoAPI = createApi({
  tagTypes: ["Logo"],
  reducerPath: "logoAPI",
  baseQuery: axiosBaseQuery(),
  credentials: "true",
  endpoints: (builder) => ({
    getLogo: builder.query({
      query: () => ({
        url: `${CUSTOMER_ACCOUNT_LOGO}?AppToken=${getLoggedInUserAccessToken()}`,
        method: WEB_REQUEST_TYPE.GET,
      }),
      transformResponse: (response, meta) => {
        const headers = meta.headers.get("is-default-logo");
        return { response, headers };
      },
    }),
    uploadLogo: builder.mutation({
      query: ({ file, fileName }) => ({
        url: `${CUSTOM_LOGO_PATH}?file_name=${fileName}`,
        method: WEB_REQUEST_TYPE.POST,
        body: file,
        headers: {
          "Content-Type": "image/png",
        },
      }),
      invalidatesTags: [{ type: "Logo", id: "CURRENT" }],
    }),
    resetLogo: builder.mutation({
      query: () => ({
        url: `${CUSTOM_LOGO_PATH}`,
        method: WEB_REQUEST_TYPE.DELETE,
      }),
      invalidatesTags: [{ type: "Logo", id: "CURRENT" }],
    }),
  }),
});

export const { useGetLogoQuery, useUploadLogoMutation, useResetLogoMutation } =
  logoAPI;
