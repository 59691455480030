import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toggleCreateUtility } from "../../../../actions/layout";
import { editOrganization } from "../../../../actions/org-manager";

import MCButton from "../../../../components/MCButton/MCButton";
import { useTranslation } from "react-i18next";
import btnStyles from "../../../../components/OrganisationExplorer/OrgExp.module.scss";
import classNames from "classnames";
import editIcon from "../../../../images/icons/common/edit.svg";
import { UncontrolledTooltip } from "reactstrap";
import { ORG_EDITING_VIEW, ORG_TABLE_VIEW } from "../../utils/constants";

const EditOrganizationButton = ({
  view,
  organization,
  onlyFeatureSelection = false,
}) => {
  const { t } = useTranslation(["multiTenancy", "common"]);
  const dispatch = useDispatch();

  const handleEditButton = () => {
    dispatch(toggleCreateUtility());
    dispatch(editOrganization(organization, view, onlyFeatureSelection));
  };

  const isAccessListLoading = useSelector(
    ({ organizationManager: { organizationAccessListLoading } }) =>
      organizationAccessListLoading,
    shallowEqual
  );

  if (onlyFeatureSelection) {
    return (
      <MCButton
        text={t("common:change")}
        color="mc-blue"
        size="md"
        className={classNames("ml-5", btnStyles.changeFeatureBtn)}
        onClick={handleEditButton}
      />
    );
  }

  const tooltipId = `edit-${organization && organization.ID}`;

  if (view === ORG_EDITING_VIEW) {
    return (
      <MCButton
        text={t("editBtntxt")}
        color="mc-blue"
        size="md"
        className={classNames(btnStyles.gradientBlue, btnStyles.newBtn)}
        onClick={handleEditButton}
        disabled={isAccessListLoading}
      />
    );
  }

  if (view === ORG_TABLE_VIEW) {
    return (
      <span>
        <img
          loading="lazy"
          src={editIcon}
          alt="edit"
          onClick={(event) => {
            event.stopPropagation();
            handleEditButton();
          }}
          id={tooltipId}
        />
        <UncontrolledTooltip target={tooltipId} placement="bottom">
          {t("common:editLabel")}
        </UncontrolledTooltip>
      </span>
    );
  }

  return "";
};

export default EditOrganizationButton;
