import {
  ADD_NEW_STEP,
  SAVE_SELECTED_TASK,
  SET_APP_DATA,
  SET_ENV_DATA,
  SAVE_SCHEDULE_DATA,
  SET_SELECTED_ACCOUNT,
  SET_SELECTED_REGION,
  SET_SELECTED_REGIONS,
  SAVE_SELECT_ALL_REGIONS,
  SET_ROLE_ARN,
  SAVE_SELECTED_INSTANCES,
  SAVE_INSTANCE_STATE_IS_DIRTY,
  SAVE_SELECTED_BLUEPRINT,
  SAVE_SELECTED_VOLUMES,
  SAVE_TASK_TAGS,
  CLEAR_TASK_TAGS,
  SAVE_SCHEDULE_TYPE,
  SAVE_DATE_TIME,
  SAVE_CRON_EXPRESSION,
  SAVE_PATCH_POLICY,
  SAVE_SERVER_GROUP,
  CREATE_SCHEDULE_REQUEST,
  CREATE_SCHEDULE_SUCCESS,
  CREATE_SCHEDULE_FAILURE,
  CLEAR_TASK_DATA,
  SAVE_SELECTED_TARGET,
  DELETE_SCHEDULE_REQUEST,
  DELETE_SCHEDULE_SUCCESS,
  DELETE_SCHEDULE_FAILURE,
  CLEAR_DELETE_SCHEDULE_DATA,
  SAVE_PATCH_TYPE,
  SAVE_PATCH_VOLUME_TYPE,
  GET_SCHEDULE_REQUEST,
  GET_SCHEDULE_SUCCESS,
  GET_SCHEDULE_FAILURE,
  SET_NEXT_STEP_STATE,
  EDIT_SCHEDULE_REQUEST,
  EDIT_SCHEDULE_SUCCESS,
  EDIT_SCHEDULE_FAILURE,
  SET_PARAMETERS,
  SAVE_SECURITY_POLICY,
  SAVE_COST_POLICY,
  SET_BLUEPRINT_CAPABILITIES,
  SAVE_SNAPSHOT_DESCRIPTION,
  SCHEDULE_STATUS_REQUEST,
  SCHEDULE_STATUS_SUCCESS,
  SCHEDULE_STATUS_FAILURE,
  REMOVE_TASK_DATA_CLEAR_STATE,
  SET_CURRENT_APP_ID,
  SET_ENABLE_MONITORING,
  SAVE_SELECTED_SCRIPT,
  SAVE_SCRIPT_ARGUMENTS,
  SAVE_SCRIPT_IAM_ROLE,
  SAVE_SCRIPT_HANDLER_NAME,
  CLEAR_PARAMETER_DATA,
  SAVE_APPLICATIONS,
  CLEAR_SCRIPT_HANDLER_NAME,
  SAVE_SNAPSHOT,
  SAVE_SELECTED_WORKSPACES,
  SAVE_SELECTED_NOTEBOOK_INSTANCES,
  SET_REBOOT_INSTANCE,
  SAVE_SELECTED_INSTANCE_TYPE,
  CLEAR_CRON_EXPRESSION,
  CLEAR_DATE_TIME,
  SET_IS_EXISTING_VPC_ON,
  SET_VPC_DROPDOWN_ITEM,
  SET_VPC_DROPDOWN_OPTION,
  RESET_VPC_CONFIG,
  SET_METRIC_TYPE,
  SET_METRIC_INTERVAL,
  CLEAR_METRIC_TYPE_AND_INTERVAL,
} from "../actionTypes/task";

import { isNotEmptyString } from "../../../utils/format-string";
import {
  getDefaultScheduleName,
  getDefaultAppName,
  getDefaultDateTime,
} from "../../../utils/task";

const initialState = {
  newStep: null,
  selectedTask: {
    type: "",
    checked: false,
  },
  scheduleData: null,
  appData: null,
  envData: {
    id: "dev",
    isSelected: true,
  },
  selectedAccount: null,
  selectedRegion: null,
  selectedRegions: [],
  isSelectAllRegions: false,
  roleArn: null,
  selectedInstances: [],
  selectedBlueprint: [],
  selectedVolumes: [],
  selectedScript: [],
  snapshotDescription: "",
  taskTags: null,
  scriptArguments: null,
  scriptIAMRole: "",
  scriptHandlerName: "",
  scheduleLater: false,
  dateTime: getDefaultDateTime(),
  cronExpression: "* * * * *",
  selectedPatchPolicy: [],
  selectedSecurityPolicy: [],
  selectedCostPolicy: [],
  selectedServerGroups: [],
  isCreating: false,
  isEditing: false,
  createdTask: false,
  editedTask: false,
  selectedTarget: "instances",
  deletingSchedule: false,
  deletedSchedule: false,
  deleteScheduleMessage: null,
  createSnapShotBeforePatch: false,
  allVolumes: false,
  gettingScheduleData: false,
  gettingScheduleDataFailed: false,
  editTaskData: false,
  editScheduleId: null,
  isRecurring: false,
  changingStatus: false,
  changedScheduleStatus: false,
  recurringScheduleSet: false,
  disableNextStep: true,
  environmentList: [
    {
      id: "dev",
      value: "Dev",
      isSelected: true,
    },
    {
      id: "test",
      value: "Test",
      isSelected: false,
    },
    {
      id: "staging",
      value: "Staging",
      isSelected: false,
    },
    {
      id: "production",
      value: "Production",
      isSelected: false,
    },
  ],
  taskParameters: [],
  parameters: null,
  selectedCapabilities: [
    {
      value: "CAPABILITY_IAM",
      label: "IAM",
    },
    {
      value: "CAPABILITY_AUTO_EXPAND",
      label: "Auto Expand",
    },
    {
      value: "CAPABILITY_NAMED_IAM",
      label: "Named IAM",
    },
  ],
  currentAppId: null,
  enableMonitoring: false,
  blueprintBundledTasks: null,
  selectedApplications: [],
  selectedSnapshots: null,
  selectedWorkspaces: null,
  selectedNotebookInstances: null,
  selectedApps: [],
  selectedSnapshotList: [],
  selectedWorkspaceList: [],
  enableRebootInstance: false,
  selectedInstanceType: null,
  instanceSelectionIsDirty: false,
  isExistingVpcOn: false,
  vpcInlineConfig: {
    region: "",
    vpc: "",
    subnets: "",
    routeTableId: "",
  },
  vpcConfigOption: {
    regionOptions: [],
    vpcOptions: [],
    subnetOptions: [],
    routeTableIdOptions: [],
  },
  metricType: null,
  metricInterval: null,
};

export default function task(state = initialState, action) {
  switch (action.type) {
    case ADD_NEW_STEP:
      return {
        ...state,
        newStep: action.payload,
      };
    case SAVE_SELECTED_TASK:
      var scheduleData = state.scheduleData;
      var appData = state.appData;
      if (
        !scheduleData ||
        (scheduleData && isNotEmptyString(scheduleData.scheduleName))
      ) {
        scheduleData = {
          scheduleName: getDefaultScheduleName(action.payload.name),
        };
      }
      if (!appData || (appData && isNotEmptyString(appData.Name))) {
        appData = {
          Name: getDefaultAppName(),
          Description: "",
        };
      }
      return {
        ...state,
        selectedTask: action.payload,
        scheduleData: scheduleData,
        appData: appData,
      };
    case SAVE_SCHEDULE_DATA:
      return {
        ...state,
        scheduleData: action.payload,
      };
    case SET_APP_DATA:
      return {
        ...state,
        appData: action.payload,
      };
    case SET_ENV_DATA:
      return {
        ...state,
        envData: action.payload,
      };
    case SET_SELECTED_ACCOUNT:
      return {
        ...state,
        selectedAccount: action.payload,
      };
    case SET_SELECTED_REGION:
      return {
        ...state,
        selectedRegion: action.payload,
      };
    case SET_SELECTED_REGIONS:
      return {
        ...state,
        selectedRegions: action.payload,
      };
    case SAVE_SELECT_ALL_REGIONS:
      return {
        ...state,
        isSelectAllRegions: action.payload,
      };
    case SET_ROLE_ARN:
      return {
        ...state,
        roleArn: action.payload,
      };
    case SAVE_SELECTED_INSTANCES:
      return {
        ...state,
        selectedInstances: action.payload,
      };
    case SAVE_INSTANCE_STATE_IS_DIRTY:
      return {
        ...state,
        instanceSelectionIsDirty: action.payload,
      };
    case SAVE_SELECTED_BLUEPRINT:
      return {
        ...state,
        selectedBlueprint: action.payload,
      };
    case SAVE_SELECTED_SCRIPT:
      return {
        ...state,
        selectedScript: action.payload,
      };
    case SAVE_SCRIPT_ARGUMENTS:
      return {
        ...state,
        scriptArguments: action.payload,
      };
    case SAVE_SCRIPT_IAM_ROLE:
      return {
        ...state,
        scriptIAMRole: action.payload,
      };
    case SAVE_SCRIPT_HANDLER_NAME:
      return {
        ...state,
        scriptHandlerName: action.payload,
      };
    case SAVE_SELECTED_VOLUMES:
      return {
        ...state,
        selectedVolumes: action.payload,
      };
    case SAVE_SNAPSHOT_DESCRIPTION:
      return {
        ...state,
        snapshotDescription: action.payload,
      };
    case SAVE_SELECTED_TARGET:
      return {
        ...state,
        //TODO
        selectedTarget: action.payload,
      };
    case SAVE_PATCH_TYPE:
      return {
        ...state,
        createSnapShotBeforePatch: action.payload,
      };
    case SAVE_PATCH_VOLUME_TYPE:
      return {
        ...state,
        allVolumes: action.payload,
      };
    case SAVE_TASK_TAGS:
      return {
        ...state,
        taskTags: action.payload,
      };

    case CLEAR_TASK_TAGS:
      return {
        ...state,
        taskTags: initialState.taskTags,
      };

    case SAVE_SCHEDULE_TYPE:
      return {
        ...state,
        scheduleLater: action.payload,
      };
    case SAVE_DATE_TIME:
      return {
        ...state,
        dateTime: action.payload,
        recurringScheduleSet: false,
      };
    case SAVE_CRON_EXPRESSION:
      return {
        ...state,
        cronExpression: action.payload,
        recurringScheduleSet: true,
      };
    case SAVE_PATCH_POLICY:
      return {
        ...state,
        selectedPatchPolicy: action.payload,
      };
    case SAVE_SECURITY_POLICY:
      return {
        ...state,
        selectedSecurityPolicy: action.payload,
      };
    case SAVE_COST_POLICY:
      return {
        ...state,
        selectedCostPolicy: action.payload,
      };
    case SAVE_SERVER_GROUP:
      return {
        ...state,
        selectedServerGroups: action.payload,
      };
    case SAVE_APPLICATIONS:
      return {
        ...state,
        selectedApplications: action.payload,
      };
    case SAVE_SNAPSHOT:
      return {
        ...state,
        selectedSnapshots: action.payload,
      };
    case SAVE_SELECTED_WORKSPACES:
      return {
        ...state,
        selectedWorkspaces: action.payload,
      };
    case SAVE_SELECTED_NOTEBOOK_INSTANCES:
      return {
        ...state,
        selectedNotebookInstances: action.payload,
      };
    case CREATE_SCHEDULE_REQUEST:
      return {
        ...state,
        isCreating: true,
      };
    case CREATE_SCHEDULE_SUCCESS:
      return {
        ...state,
        isCreating: false,
        createdTask: true,
        blueprintTemplateUrl: action.payload.TemplateUrl,
      };
    case CREATE_SCHEDULE_FAILURE:
      return {
        ...state,
        isCreating: false,
        createdTask: false,
        errorMessage: action.payload,
      };
    case EDIT_SCHEDULE_REQUEST:
      return {
        ...state,
        isEditing: true,
      };
    case EDIT_SCHEDULE_SUCCESS:
      return {
        ...state,
        isEditing: false,
        editedTask: true,
      };
    case EDIT_SCHEDULE_FAILURE:
      return {
        ...state,
        isEditing: false,
        errorMessage: action.payload,
      };
    case CLEAR_TASK_DATA:
      return {
        ...state,
        ...initialState,
        dateTime: getDefaultDateTime(),
        taskDataCleared: true,
      };
    case DELETE_SCHEDULE_REQUEST:
      return {
        ...state,
        deletingSchedule: true,
        deleteScheduleMessage: action.payload,
      };
    case DELETE_SCHEDULE_SUCCESS:
      return {
        ...state,
        deletingSchedule: false,
        deletedSchedule: true,
        deleteScheduleMessage: action.payload,
      };
    case DELETE_SCHEDULE_FAILURE:
      return {
        ...state,
        deletingSchedule: false,
        deletedSchedule: false,
        deleteScheduleMessage: action.payload,
      };
    case CLEAR_DELETE_SCHEDULE_DATA:
      return {
        ...state,
        deletingSchedule: initialState.deletedSchedule,
        deletedSchedule: initialState.deletedSchedule,
        deleteScheduleMessage: initialState.deleteScheduleMessage,
      };
    case GET_SCHEDULE_REQUEST:
      return {
        ...state,
        gettingScheduleData: true,
        gettingScheduleDataFailed: initialState.gettingScheduleDataFailed,
      };
    case GET_SCHEDULE_SUCCESS:
      const editScheduleData = action.payload;
      return {
        ...state,
        gettingScheduleData: false,
        editTaskData: true,
        editScheduleId: editScheduleData.scheduleId,
        selectedTask: editScheduleData.selectedTask,
        scheduleData: editScheduleData.scheduleData,
        selectedTarget: editScheduleData.selectedTarget,
        selectedInstances: editScheduleData.selectedInstances,
        taskTags: editScheduleData.taskTags,
        scheduleLater: editScheduleData.scheduleLater,
        dateTime: editScheduleData.dateTime,
        isRecurring: editScheduleData.isRecurring,
        cronExpression: editScheduleData.cronExpression,
        recurringScheduleSet: editScheduleData.recurringScheduleSet,
        selectedServerGroups: editScheduleData.selectedServerGroups,
        selectedPatchPolicy: editScheduleData.selectedPatchPolicy
          ? [editScheduleData.selectedPatchPolicy]
          : initialState.selectedPatchPolicy,
        createSnapShotBeforePatch: editScheduleData.createSnapShotBeforePatch,
        allVolumes: editScheduleData.allVolumes,
        snapshotDescription: editScheduleData.snapshotDescription,
        selectedVolumes: editScheduleData.selectedVolumes
          ? editScheduleData.selectedVolumes
          : initialState.selectedVolumes,
        selectedApps: editScheduleData.selectedApps
          ? editScheduleData.selectedApps
          : initialState.selectedApps,
        selectedSnapshotList: editScheduleData.selectedSnapshotList
          ? editScheduleData.selectedSnapshotList
          : initialState.selectedSnapshotList,
        selectedWorkspaceList: editScheduleData.selectedWorkspaceList
          ? editScheduleData.selectedWorkspaceList
          : initialState.selectedWorkspaceList,
        taskParameters: editScheduleData.taskParameters,
        selectedAccount: editScheduleData.selectedAccount,
        selectedRegion: editScheduleData.selectedRegion,
        selectedRegions: editScheduleData.selectedRegions,
        isEditingSchedule: editScheduleData.isEditingSchedule,
        enableRebootInstance: editScheduleData.enableRebootInstance,
        selectedInstanceType: editScheduleData.selectedInstanceType,
        selectedNotebookInstanceList:
          editScheduleData.selectedNotebookInstanceList,
      };
    case GET_SCHEDULE_FAILURE:
      return {
        ...state,
        gettingScheduleData: false,
        errorMessage: action.payload,
        gettingScheduleDataFailed: true,
      };
    case SET_NEXT_STEP_STATE:
      return {
        ...state,
        disableNextStep: action.payload,
      };
    case SET_PARAMETERS:
      return {
        ...state,
        parameters: action.payload,
      };
    case SET_BLUEPRINT_CAPABILITIES:
      return {
        ...state,
        selectedCapabilities: action.payload,
      };
    case SCHEDULE_STATUS_REQUEST:
      return {
        ...state,
        changingStatus: true,
        changedScheduleStatus: false,
      };
    case SCHEDULE_STATUS_SUCCESS:
      return {
        ...state,
        changingStatus: false,
        changedScheduleStatus: true,
      };
    case SCHEDULE_STATUS_FAILURE:
      return {
        ...state,
        changingStatus: false,
        errorMessage: action.payload,
      };
    case REMOVE_TASK_DATA_CLEAR_STATE:
      return {
        ...state,
        taskDataCleared: false,
      };
    case SET_CURRENT_APP_ID:
      return {
        ...state,
        currentAppId: action.payload,
      };
    case SET_ENABLE_MONITORING:
      return {
        ...state,
        enableMonitoring: action.payload,
      };
    case SET_REBOOT_INSTANCE:
      return {
        ...state,
        enableRebootInstance: action.payload,
      };
    case CLEAR_PARAMETER_DATA:
      return {
        ...state,
        parameters: initialState.parameters,
      };
    case CLEAR_SCRIPT_HANDLER_NAME:
      return {
        ...state,
        scriptHandlerName: initialState.scriptHandlerName,
      };
    case SAVE_SELECTED_INSTANCE_TYPE:
      return {
        ...state,
        selectedInstanceType: action.payload,
      };
    case CLEAR_DATE_TIME:
      return {
        ...state,
        dateTime: getDefaultDateTime(),
        recurringScheduleSet: initialState.recurringScheduleSet,
      };
    case CLEAR_CRON_EXPRESSION:
      return {
        ...state,
        cronExpression: initialState.cronExpression,
        recurringScheduleSet: initialState.recurringScheduleSet,
      };
    case SET_IS_EXISTING_VPC_ON:
      return {
        ...state,
        isExistingVpcOn: Boolean(action.payload),
      };

    case SET_VPC_DROPDOWN_ITEM: {
      const { key, value } = action.payload;
      return {
        ...state,
        vpcInlineConfig: {
          ...state.vpcInlineConfig,
          [key]: value,
        },
      };
    }

    case SET_VPC_DROPDOWN_OPTION: {
      return {
        ...state,
        vpcConfigOption: {
          ...state.vpcConfigOption,
          ...action.payload,
        },
      };
    }

    case RESET_VPC_CONFIG: {
      return {
        ...state,
        vpcConfigOption: {
          ...initialState.vpcConfigOption,
        },
        vpcInlineConfig: {
          ...initialState.vpcInlineConfig,
        },
      };
    }

    case SET_METRIC_TYPE:
      return {
        ...state,
        metricType: action.payload,
      };

    case SET_METRIC_INTERVAL:
      return {
        ...state,
        metricInterval: action.payload,
      };

    case CLEAR_METRIC_TYPE_AND_INTERVAL:
      return {
        ...state,
        metricType: initialState.metricType,
        metricInterval: initialState.metricInterval,
      };

    default:
      return state;
  }
}
