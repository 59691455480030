import { createApi } from "@reduxjs/toolkit/query/react";
import {
  BULK_DISCOUNT_MARKETPLACE_CHARGE,
  BULK_REMOVE_DISCOUNT_MARKETPLACE_CHARGE,
  DISCOUNT_MARKETPLACE_CHARGE,
  MARKETPLACE_CHARGES_LIST,
  MARKETPLACE_CHARGES_SUMMARY,
  MARKETPLACE_EDIT_CREDIT,
  MARKETPLACE_TOTAL_CREDITS,
  MARKETPLACE_UNASSIGN_CREDIT,
} from "../../../../../../config/service";
import {
  WEB_REQUEST_TYPE,
  getApiService,
} from "../../../../../../helpers/apiService";
import { axiosBaseQuery } from "../../../../../../config/customBaseQuery";
import { isArrayWithLength } from "../../../../../../utils/array-methods";

export const fetchMarketplaceChargesSummary = ({
  BillingPeriod,
  OrganizationId,
  Dimension,
  Limit,
  Offset,
  SortBy,
  SortOrder,
}) => {
  let url = MARKETPLACE_CHARGES_SUMMARY.replace("{orgId}", OrganizationId);
  let filters = {};

  filters["BillingPeriod"] = BillingPeriod;
  if (Dimension) {
    filters["Dimension"] = Dimension;
  }

  url += `?Filters=${encodeURIComponent(
    JSON.stringify(filters)
  )}&Limit=${Limit}&Offset=${Offset}`;

  if (SortBy) {
    url += `&SortBy=${SortBy}&SortOrder=${SortOrder}`;
  }
  return getApiService(WEB_REQUEST_TYPE.GET, url);
};

export const fetchMarketplaceCharges = ({
  BillingPeriod,
  OrganizationId,
  Dimension,
  groupKey,
  Limit,
  Offset,
  SortBy,
  SortOrder,
  FilterModel,
  Status,
}) => {
  let url = MARKETPLACE_CHARGES_LIST.replace("{orgId}", OrganizationId);
  let filters = {};
  filters["BillingPeriod"] = BillingPeriod;

  if (Status) {
    filters.Status = Status;
  }
  if (isArrayWithLength(FilterModel)) {
    filters.KeywordSearch = [...FilterModel];
  }
  if (Dimension) {
    filters[Dimension] = groupKey;
  }

  url += `?Filters=${encodeURIComponent(
    JSON.stringify(filters)
  )}&Limit=${Limit}&Offset=${Offset}`;
  if (SortBy) {
    url += `&SortBy=${SortBy}&SortOrder=${SortOrder}`;
  }

  return getApiService(WEB_REQUEST_TYPE.GET, url);
};

export const marketplaceChargesAPI = createApi({
  reducerPath: "marketplaceCharges",
  baseQuery: axiosBaseQuery(),
  credentials: "true",
  endpoints: (builder) => ({
    discountMarketPlaceCharge: builder.mutation({
      query({ creditID, orgId, body }) {
        let url = DISCOUNT_MARKETPLACE_CHARGE.replace("{orgId}", orgId);
        url = url.replace("{creditId}", creditID);

        return {
          url: url,
          method: WEB_REQUEST_TYPE.POST,
          body,
        };
      },
    }),
    editMarketPlaceCharge: builder.mutation({
      query({ customLineItemId, orgId, body }) {
        return {
          url: MARKETPLACE_EDIT_CREDIT.replace("{orgId}", orgId).replace(
            "{customLineItemId}",
            customLineItemId
          ),
          method: WEB_REQUEST_TYPE.PATCH,
          body,
        };
      },
    }),
    getTotalMarketPlaceCharges: builder.query({
      query({ orgId, BillingPeriod }) {
        return {
          url: `${MARKETPLACE_TOTAL_CREDITS.replace(
            "{orgId}",
            orgId
          )}?billingPeriod=${BillingPeriod}`,
          method: WEB_REQUEST_TYPE.GET,
        };
      },
    }),
    bulkDiscountMarketPlaceCharge: builder.mutation({
      query({ selectedCredits, orgId }) {
        let url = BULK_DISCOUNT_MARKETPLACE_CHARGE.replace("{orgId}", orgId);
        return {
          url: url,
          method: WEB_REQUEST_TYPE.POST,
          body: selectedCredits,
        };
      },
    }),
    bulkRemoveDiscountMarketPlaceCharge: builder.mutation({
      query({ payload, orgId }) {
        let url = BULK_REMOVE_DISCOUNT_MARKETPLACE_CHARGE.replace(
          "{orgId}",
          orgId
        );
        return {
          url: url,
          method: WEB_REQUEST_TYPE.POST,
          body: payload,
        };
      },
    }),
    unAssignCredit: builder.mutation({
      query({ creditID, orgId }) {
        return {
          url: MARKETPLACE_UNASSIGN_CREDIT.replace("{orgId}", orgId).replace(
            "{creditId}",
            creditID
          ),
          method: WEB_REQUEST_TYPE.PUT,
        };
      },
    }),
  }),
});

export const {
  useDiscountMarketPlaceChargeMutation,
  useEditMarketPlaceChargeMutation,
  useGetTotalMarketPlaceChargesQuery,
  useBulkDiscountMarketPlaceChargeMutation,
  useBulkRemoveDiscountMarketPlaceChargeMutation,
  useUnAssignCreditMutation,
} = marketplaceChargesAPI;
