import React, { useEffect, useRef } from "react";
import classnames from "classnames";
import s from "./SingleCheckbox.module.scss";
import { UncontrolledTooltip } from "reactstrap";
import EllipsisWithTooltip from "../../utils/ellipsis-tooltip";

const SingleCheckbox = ({
  text,
  checked,
  onChange,
  disabled = false,
  tooltip,
  id,
  extraClassName,
  containerClassName,
  checkboxClassName,
  indeterminate = false,
}) => {
  const checkboxRef = useRef(null);
  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = indeterminate;
    }
  }, [indeterminate]);

  return (
    <div className={classnames(s.ContainerDiv, containerClassName)}>
      <label className={classnames(s.Container, extraClassName)}>
        <span className={classnames(s.textContainer,disabled && s.DisabledCheckbox)}>
          {typeof text === "string" ? (
            <EllipsisWithTooltip text={text} className={s.tooltipContainer} />
          ) : (
            text
          )}
        </span>
        <input
          type="checkbox"
          onChange={onChange}
          checked={checked}
          disabled={disabled}
          id={id}
          ref={checkboxRef}
        />
        <span
          className={classnames(
            s.Checkmark,
            [disabled && s.DisabledCheckbox],
            [indeterminate && s.IndeterminateCheckbox],
            checkboxClassName
          )}
        ></span>
        {tooltip && (
          <>
            <span className="ml-2">
              <i
                className={classnames("fa fa-info-circle", s.infoIcon)}
                id={id}
              ></i>
            </span>
            <UncontrolledTooltip placement="right" target={id}>
              {tooltip}
            </UncontrolledTooltip>
          </>
        )}
      </label>
    </div>
  );
};

export default SingleCheckbox;
